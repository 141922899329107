.wrapper {
    width: 100%;
    background-color: var(--color-primary);
    height: calc(100vh - 13.5rem);
    display: grid;
    place-items: center;
    margin-bottom: 7rem;

    @media (max-width: 1000px) {
        height: auto;
        margin: 0;
    }
}

.section {
    width: 100%;
    max-width: 200rem;
    display: grid;
    grid-template-columns: 15rem 1fr;

    @media (max-width: 1300px) {
        grid-template-columns: 10rem 1fr;
    }

    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
        position: relative;
        height: 0;
        padding-bottom: 65%;
    }
}

.sideWrapper {
    position: relative;
    overflow: hidden;
    margin: 2rem 0 12rem 0;

    @media (max-width: 1000px) {
        display: none;
    }
}

.side {
    position: absolute;
    bottom: 0;
    left: 44%;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    display: flex;
    gap: 4rem;
    font-size: 1.7rem;
    color: var(--color-light-gray);
    white-space: nowrap;
}

.image {
    width: 100%;
    height: calc(100vh - 5rem);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--color-primary);

    @media (max-width: 1000px) {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}