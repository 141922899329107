@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
    --color-primary: #222526;
    --color-dark: #131215;
    --color-light-gray: #EEEEEE;
    --color-mid-gray: #999999;
}

* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    padding: 0;
    margin: 0;
    text-decoration: none;
}

html {
    font-size: 10px;
}
