.wrapper {
    width: 100%;
    background-color: var(--color-primary);
    display: grid;
    place-items: center;
}

.header {
    width: 100%;
    max-width: 200rem;
    padding: 5rem 0;
    display: grid;
    grid-template-columns: 15rem 1fr max-content 15rem;
    align-items: center;

    @media (max-width: 1300px) {
        grid-template-columns: 10rem 1fr max-content 10rem;
    }

    @media (max-width: 1000px) {
        grid-template-columns: 1fr max-content;
        padding: 3rem 5rem;
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
}

.icon {
    display: grid;
    place-self: center;
    place-items: center;
    font-size: 1.7rem;
    color: var(--color-light-gray);

    &:hover {
        color: var(--color-mid-gray);
    }

    @media (max-width: 1000px) {
        display: none;
    }
}

.logo {
    margin-bottom: 0.8rem;

    img {
        height: 2.5rem;
        filter: brightness(0) invert(1);
    }

    @media (max-width: 700px) {
        display: none;
    }
}

.links {
    display: flex;
    gap: 2rem;
}

.link {
    color: var(--color-light-gray);
    font-size: 1.5rem;
    cursor: pointer;
    text-underline-offset: 0.5rem;

    &:hover {
        text-decoration: underline;
        color: var(--color-mid-gray);
    }
}

.link__selected {
    text-decoration: underline;
    color: var(--color-mid-gray);
}