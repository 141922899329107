.heading {
    width: 100%;
    display: grid;
    grid-template-columns: 10rem 1fr;
    gap: 3rem;
    align-items: center;

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

.missionPatch {
    width: 10rem;
    height: 10rem;
}

.date {
    font-size: 1.7em;
}

.name {
    font-size: 3.5rem;
    font-weight: 600;

    @media (max-width: 700px) {
        font-size: 3rem;
        margin-top: 0.5rem;
    }
}