.grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
    gap: 5rem 3rem;
}

.launch {
    width: 100%;
    height: auto;

    &:hover {
        transform: scale(1.03);
    }
}

.image {
    width: 100%;
    height: auto;
    padding-bottom: 65%;
    position: relative;
    background-color: var(--color-dark);

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
        background-color: var(--color-dark);
    }
}

.name {
    font-size: 2.2rem;
    font-weight: 500;
    color: var(--color-light-gray);
    margin-top: 1.5rem;
}

.date {
    font-size: 1.5rem;
    color: var(--color-mid-gray);
    margin-top: 0.5rem;
}