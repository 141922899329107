.wrapper {
    width: 100%;
    display: grid;
    place-items: center;
}

.wrapper__dark {
    background-color: var(--color-primary);
    min-height: calc(100vh - 13.5rem);
}

.main {
    width: 100%;
    max-width: 200rem;
    padding: 5rem 15rem;

    @media (max-width: 1300px) {
        padding: 5rem 10rem;
    }


    @media (max-width: 1000px) {
        padding: 5rem;
    }
}