.gallery {
    width: 100%;
    padding: 10rem 0;
    background-color: var(--color-primary);
    display: flex;
    flex-direction: column;
    gap: 3rem;
    overflow: hidden;
    margin-top: 7rem;
}

.rowWrapper {
    position: relative;
    width: 100%;
    height: 30rem;

    &:nth-child(odd) {
        left: 15rem;

        @media (max-width: 1300px) {
            left: 10rem;
        }

        @media (max-width: 1000px) {
            left: 5rem;
        }
    }

    &:nth-child(even) {
        right: 15rem;

        .row {
            justify-content: end;
        }

        @media (max-width: 1300px) {
            right: 10rem;
        }

        @media (max-width: 1000px) {
            right: 5rem;
        }
    }

    @media (max-width: 700px) {
        display: none;
    }
}

.row {
    width: 100%;
    display: flex;
    gap: 3rem;

    @media (max-width: 700px) {
        flex-direction: column;
    }
}

.image {
    width: auto;
    height: 30rem;

    @media (max-width: 700px) {
        width: 100%;
        height: auto;
    }
}

.allImages {
    display: none;
    flex-direction: column;
    padding: 0 5rem;
    gap: 2rem;

    @media (max-width: 700px) {
        display: flex;
    }
}