.container {
    width: 100%;
    display: grid;
    grid-template-columns: 30rem 1fr;
    align-items: center;
    gap: 5rem;

    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

.image {
    width: 100%;
    height: 100%;

    @media (max-width: 1000px) {
        max-width: 30rem;
    }
}

.title {
    font-size: 2.7rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.texts {
    font-size: 1.7rem;
    margin-bottom: 1.5rem;
}

.details {
    list-style: none;
}

.item {
    display: grid;
    grid-template-columns: 1.7rem 1fr;
    gap: 1rem;
    align-items: center;
    margin-bottom: 0.7rem;
    font-size: 1.7rem;
}

.icon {
    justify-self: center;
    color: var(--color-mid-gray);
}