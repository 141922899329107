.details {
    font-size: 1.7rem;
    line-height: 1.3;
    margin-top: 3rem;
}

.info {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;

    @media (max-width: 1000px) {
        gap: 7rem;
    }
}
